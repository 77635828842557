var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"color-primary h-100 page_bg_grey blank-aside-js",class:(_vm.isOpenAside=='true')?'blank-aside':''},[_c('p',{staticClass:"main-title weight-700"},[_vm._v(" "+_vm._s(_vm.$t('ASIDE.SHANIV_PACKAGES'))+" ")]),_c('section',{staticClass:"filter-part content-margin d-flex"},[_c('div',{staticClass:"form-item"},[_c('label',[_vm._v(_vm._s(_vm.$t('MANAGE_PRODUCT.SELECT_CATEGORY')))]),_vm._m(0)]),_c('div',{staticClass:"form-item"},[_c('label',[_vm._v(_vm._s(_vm.$t('MANAGE_PRODUCT.SELECT_PROVIDER')))]),_vm._m(1)])]),_c('section',{staticClass:"table-content-part"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"search-bar"},[_c('p',{staticClass:"search-label"},[_vm._v(_vm._s(_vm.$t('COMMON.SEARCH')))]),_c('v-text-field',{staticClass:"search-form",attrs:{"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('v-data-table',{staticClass:"cus-table editable-table main-table striped-table",attrs:{"headers":_vm.tableHeaders,"search":_vm.search,"items":_vm.package_list,"options":_vm.options,"loading":_vm.loading,"server-items-length":_vm.maxPackageNumber,"footer-props":{
        'items-per-page-options': _vm.itemsPerPageOptions
      },"item-key":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-checkbox',{staticClass:"cus-checkbox",attrs:{"color":"#0D3856"},on:{"change":function($event){return _vm.changeState(item.id, item.active)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})],1)]}},{key:"item.editing",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: 'ShanivPackage', params: {id: item.id }},"tag":"a"}},[_c('v-icon',{attrs:{"medium":"","color":"dark"}},[_vm._v("edit")])],1)]}},{key:"item.image",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.showImage(item.image)}}},[_vm._v(_vm._s(_vm.$t('ADS_MANAGEMENT.SHOW')))])]}}],null,true)})],1),_c('section',{staticClass:"botton-action"},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{name: 'ShanivPackageCreate'},"tag":"a"}},[_c('button',{staticClass:"primary-btn bg-yellow"},[_vm._v(" "+_vm._s(_vm.$t('COMMON.SAVE'))+" ")])]),_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{name: 'ShanivPackageCreate'},"tag":"a"}},[_c('button',{staticClass:"primary-btn"},[_vm._v(" "+_vm._s(_vm.$t('COMMON.NEW'))+" ")])])],1),_c('modal',{staticClass:"imageModal CEModal modal-scroll-bar",attrs:{"width":300,"adaptive":true,"name":"imageModal"}},[_c('button',{staticClass:"button close-btn",on:{"click":function($event){return _vm.closeImageModal()}}},[_c('img',{attrs:{"src":"/assets/img/close.svg","alt":""}})]),_c('img',{staticClass:"main-image w-100",attrs:{"src":_vm.imageUrl,"alt":""}})]),_c('loading',{attrs:{"active":_vm.loading,"can-cancel":false,"is-full-page":true},on:{"update:active":function($event){_vm.loading=$event}}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-t-5 m-b-15 icon-input"},[_c('input',{staticClass:"input",attrs:{"type":"text"}}),_c('img',{attrs:{"src":"/assets/img/icons/search-light.svg","alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-t-5 m-b-15 icon-input"},[_c('input',{staticClass:"input",attrs:{"type":"text"}}),_c('img',{attrs:{"src":"/assets/img/icons/search-light.svg","alt":""}})])}]

export { render, staticRenderFns }